
export const api_getPostcodeAddresses = (postcode) => {
  return requestApi(`/address/postcode/${postcode}`, 'get', {postcode})
}

export const api_requestQuote = (data) => {
  return requestApi(`/widget/quotes`, 'post', data)
}

export const api_updateQuote = (quoteId, data) => {
  return requestApi(`/widget/quotes/${quoteId}`, 'put', data)
}



export const requestApi = async (
  path = '',
  method = 'GET',
  data = null,
) => {

  const url = `${process.env.REACT_APP_API_URL}${path}`
  console.log(url)
  let request = {
    method: method.toUpperCase(),
    cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'Accept' : 'application/json' }
  }
  if(method.toLowerCase() !== 'get'){
    request.body = JSON.stringify(data)
  }
  const response = await fetch(url, request)

  if(response.status === 422){
    const result = await response.json()
    let error = Object.keys(result).map(key => result[key]);
    throw Error(error.toString())
  }
  else if (response.status < 200 || response.status >= 300) {
    const result = await response.json()
    throw Error('There was an error with your request. Please try again or contact support@hso.co.uk.')
  }

  if(response.status === 204){
    return true;
  }
  return await response.json()
}
