import {
  Alert,
  Button,
  Card,
  CardBody,
  CardText,
  Col, Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Row, Spinner
} from 'reactstrap'
import { useState } from 'react'
import { SelectMod } from './SelectMod'
import { api_getPostcodeAddresses } from './api'

export const Address = ({
  selectedAddress,
  handleAddressSelect
}) =>{
  const [addresses, setAddresses] = useState([]);
  const [postcode, setPostcode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const getAddresses = (event) =>{
    event.preventDefault();
    if(postcode){
      setError('')
      setLoading(true)
      setAddresses([])
      handleAddressSelect(null)
      api_getPostcodeAddresses(postcode).then((result) => {
        console.log(result)
        setAddresses(result.map(address => {
          return {label: `${address.address}, ${address.place}`, value: address.id}
        }))
        setLoading(false)
      }, (error) => {
        setLoading(false)
        setError(error.message)
      })
    }else{
      setError('You must provide a full and valid UK postcode.')
    }
  }

  return (
        <Card>
          <CardBody>
            {error &&
              <Alert color='danger'>{error}</Alert>
            }
            <CardText>I need the service installed at:</CardText>
            <Row>
              <Col lg={6} md={8} sm={12}>
                <Form onSubmit={getAddresses}>
                  <InputGroup className='mb-3'>
                    <Input placeholder={'Business postcode...'} onChange={(event) => {setPostcode(event.target.value)}}/>
                    <InputGroupAddon addonType="append">
                      <Button onClick={getAddresses}>
                        {loading ?
                          <Spinner size="sm" color="white" />
                          :
                          <i className="bi-arrow-right"></i>
                        }
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </Form>
              </Col>
            </Row>
            <SelectMod
              maxMenuHeight={200}
              placeholder='Select your address...'
              isDisabled={addresses.length === 0}
              options={addresses}
              value={selectedAddress}
              onChange={handleAddressSelect}
            />
          </CardBody>
        </Card>
  )
}
