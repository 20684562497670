import { Alert, Button, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap'

export const Confirmation = ({
  quoteNumber,
  reset
}) =>{

  return (
    <Card>
      <CardBody>
        <Row className='mt-3'>
          <Col className='d-flex flex-column justify-content-center align-items-center'>
            <p className='h2 text-primary font-weight-bold'>We'll Be In Touch Shortly</p>
          </Col>
        </Row>
        <CardTitle className='h4'>Confirmation</CardTitle>
        <Alert color={'success'}><CardText>Your Quote Number: <span className='font-weight-bold'>{quoteNumber}</span></CardText></Alert>
        <p>Please make a note of this quote number for your reference as one of our leased line advisors will contact you shortly to discuss your quote in more detail.</p>
        <p>In the meantime, if you have any questions regarding your quote, please contact us on <span><a href='tel:02078474510'>020 7847 4510</a></span> or email <span><a href='mailto:info@hso.co.uk'>info@hso.co.uk</a></span>.</p>
        <p className='text-warning font-weight-bold'>
          hSo Leased Line Team
        </p>
        <div className='d-flex justify-content-end'>
          <Button color='dark' size='sm' onClick={reset}>Start a new quote</Button>
        </div>

      </CardBody>
    </Card>
  )
}
