import './App.scss';
import {
  Card,
  CardBody,
  CardText,
  Col,
  Collapse,
  Fade,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  Row
} from 'reactstrap'
import { init as initApm } from '@elastic/apm-rum'
import { useState } from 'react'
import { Address } from './Address'
import { Bandwidth } from './Bandwidth'
import { Contact } from './Contact'
import { Confirmation } from './Confirmation'
import Loadable from 'react-loading-overlay'
import { api_requestQuote, api_updateQuote } from './api'
import { transformPostcode } from './helpers'


const apm = initApm({
  serviceName: 'hso-leased-line-widget',
  serverUrl: 'https://apm.hso-group.net',
  serviceVersion: process.env.REACT_APP_GIT_SHA,
  environment: process.env.REACT_APP_ENV,
  distributedTracingOrigins: [
    process.env.REACT_APP_API_URL
  ]
});

function App() {

  const [activeStep, setActiveStep] = useState(1);
  const [quoteId, setQuoteId] = useState(1);
  const [updateError, setUpdateError] = useState('');
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState({name: '', email: '', phone: ''});


  const handleAddressSelect = (address) =>{
    setSelectedAddress(address)
    if(address !== null){
      setActiveStep(2)
    }
  }
  const handleSpeedSelect = (speed) =>{
    setLoading(true)
    if(speed !== null){
      api_requestQuote({
        cdr: speed,
        bearer: speed === 100 ? 100 : speed < 5000 ? 1000 : 10000,
        address_id: selectedAddress.value,
        term: 3
      }).then((result) => {
        setQuoteId(result.id)
        setActiveStep(3)
        setLoading(false)
      }, (error) => {
        console.log(error)
        setLoading(false)
      })
    }
  }

  const handleContactDetails = (detail) => {
    setContact({...contact, ...detail})
  }

  const handleSend = () => {
    setLoading(true)
    setUpdateError('');
    api_updateQuote(quoteId, contact).then((result) => {
      setLoading(false)
      setActiveStep(4)
    }, (error) => {
      setUpdateError( error.message);
      setLoading(false)
    })
  }

  const reset = () => {
    setActiveStep(1)
    setSelectedAddress(null)
    setUpdateError('')
    setContact({name: '', email: '', phone: ''})
  }

  const getIframeUrl = () => {
    if(activeStep === 1){
      return 'https://www.hso.co.uk/mq3page4code/1-which-postcode.html'
    }
    if(activeStep === 2){
      return 'https://www.hso.co.uk/mq3page4code/3-which-bandwidth.html'
    }
    if(activeStep === 3){
      return 'https://www.hso.co.uk/mq3page4code/4-contact-details.html'
    }
    if(activeStep === 4){
      return 'https://www.hso.co.uk/mq3page4code/5-thanks-general.html'
    }
  }
  return (
    <div className="container-fluid">
        <Row className='d-flex justify-content-center'>
          <Col md={8} sm={12 }className='p-0'>
            <Card>
              <CardBody className={'bg-primary'}>
                <Row className='bg-white borderRadius'>
                  <Col col-xs={12} sm={12} md={4} lg={4} className='d-flex flex-column justify-content-sm-center align-items-sm-center align-items-lg-start align-items-md-start'>
                    <a href='https://hso.co.uk' target="_blank" rel="noopener noreferrer">
                      <img width='100px' height='50px' src='https://www.hso.co.uk/sites/default/files/hSo_logo.svg'/>
                    </a>
                  </Col>
                  <Col col-xs={12} sm={12} md={4} lg={4} className='d-flex flex-column flex-wrap justify-content-sm-center align-items-md-right align-items-lg-right align-items-sm-center'>
                      <span className={'text-dark h5 info'}><i className='bi-telephone-fill mr-1'/><a href='tel:02078474510'>020 7847 4510</a></span>
                  </Col>
                  <Col col-xs={12} sm={12} md={4} lg={4} className='d-flex flex-column flex-wrap justify-content-sm-center align-items-sm-center align-items-lg-end'>
                      <span className={'fs-5 h5 info'}>
                        <i className='bi-envelope-fill mr-1'/>
                        <a href='mailto:info@hso.co.uk'>info@hso.co.uk</a>
                      </span>
                  </Col>
                </Row>
                {activeStep === 1 &&
                  <Row className='mt-3'>
                    <Col className='d-flex flex-column justify-content-center align-items-center'>
                      <p className='h2 text-white mt-3'>Get Your Leased Line Quote Today</p>
                      <p className='h5 text-white mt-3'>We compare wholesale quotes to find you the best option</p>
                    </Col>
                  </Row>
                }
                <Row className='d-flex justify-content-center mt-3'>
                  <Col md={8} sm={12} className='d-flex flex-column'>
                    <Loadable
                      animate={false}
                      active={loading}
                      spinner
                      spinnerSize={'60px'}
                      color={'rgb(62,81,91)'}
                      background={'rgba(255,255,255, 0.8)'}
                    >
                      <div className='bg-white rounded'>
                        {activeStep === 1 &&
                        <Fade in={true}>
                          <Address
                            handleAddressSelect={handleAddressSelect}
                            selectedAddress={selectedAddress}
                          />
                        </Fade>

                        }
                        {activeStep === 2 &&

                        <Fade in={true}>
                          <Bandwidth
                            handleSelect={handleSpeedSelect}
                            address={selectedAddress}
                          />
                        </Fade>

                        }
                        {activeStep === 3 &&

                        <Fade in={true}>
                          <Contact
                            updateError={updateError}
                            handleSend={handleSend}
                            handleDetails={handleContactDetails}
                            contact={contact}
                          />
                        </Fade>

                        }
                        {activeStep === 4 &&

                        <Fade in={true}>
                          <Confirmation
                            quoteNumber={quoteId}
                            reset={reset}
                          />
                        </Fade>

                        }
                      </div>
                    </Loadable>

                  </Col>
                </Row>
                {activeStep === 1 &&
                  <div className='d-flex align-content-start mt-3'>
                    <i className='bi-info-circle-fill'/>
                    <CardText
                      className='mx-2'>
                      We use cookies to make this site work, measure our marketing and show you relevant ads.
                      <a href='https://www.hso.co.uk/privacypolicy' target="_blank" rel="noopener noreferrer">
                        <span className='text-white ml-1'>Learn more</span>
                      </a>.
                    </CardText>
                  </div>
                }
                {activeStep === 3 &&
                <div className='d-flex align-content-start mt-3'>
                  <i className='bi-lock'/>
                  <CardText
                    className='mx-2'>
                   Your information is encrypted and protected under our
                    <a href='https://www.hso.co.uk/privacypolicy' target="_blank" rel="noopener noreferrer">
                      <span className='text-white ml-1'> privacy policy</span>
                    </a>.
                  </CardText>
                </div>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      <iframe width="1" scrolling="no" height="1" frameBorder="0" src={getIframeUrl()}></iframe>
    </div>
  );
}

export default App;
